import glConf from './glConf';
import glType from './glType';
import glResize from './glResize';
import glUpdate from './glUpdate';
import glParam from './glParam';
import glTextures from './glTextures';
import image from './image';
import intro from './intro';
import filter from './filter';
import attentionFoot from './attentionFoot';
import attentionHem from './attentionHem';
import ready from './ready';
import making from './making';
import webgl from './index';
import makingFinish from './makingFinish';


export default class contents {

  constructor(opt) {

    this.camera = undefined
    this.renderer = undefined

    this._stats = undefined

    this._logo = undefined
    this._intro = undefined
    this._filter = undefined
    this._attentionFoot = undefined
    this._attentionHem = undefined
    this._ready = undefined
    this._making = undefined
    this._scanStatus = undefined

    this._bgColor = undefined
    this._bgAlpha = 1
    this._renderCnt = undefined

    this.onCompleteMaking = undefined

    this.opt = opt

    this._init();

  }


  _init() {

    this._renderCnt = 0;
    this._scanStatus = null;

    if(glConf.IS_PREVIEW) {
      // this._stats = new Stats();
      // document.body.appendChild(this._stats.domElement);

      // document.getElementById('webgl-debug_intro').addEventListener('click', () => {
      //   this.setScene(glType.SCENE.INTRO);
      // });
      // document.getElementById('webgl-debug_ready').addEventListener('click', () => {
      //   this.setScene(glType.SCENE.READY);
      // });
      // document.getElementById('webgl-debug_making').addEventListener('click', () => {
      //   this.setScene(glType.SCENE.MAKING);
      // });
      // document.getElementById('webgl-debug_shoes').addEventListener('click', () => {
      //   this.setScene(glType.SCENE.SHOES);
      // });

      // 仮
      // window.addEventListener('touchmove', (e) => {
      //   e.preventDefault();
      // }, {passive:false});
    }

    this.renderer = new THREE.WebGLRenderer({
      canvas             : this.opt.el,
      alpha              : true,
      antialias          : false,
      stencil            : false,
      depth              : true,
      premultipliedAlpha : true,
      powerPreference    : 'low-power'
    });
    this.renderer.autoClear = true;

    this.camera = new THREE.PerspectiveCamera(45, 1, 0.1, 50000);

    webgl.renderer = this.renderer;
    webgl.camera = this.camera;

    this.mainScene = new THREE.Scene();

    // イントロ
    this._intro = new intro();
    this._intro.init({
      startCallBack: this.opt.startCallback
    });
    this.mainScene.add(this._intro);
    this._intro.onComplete = () => {
      this._eCompleteIntro()
    }

    // フィルター
    this._filter = new filter()
    this._filter.init();
    this.mainScene.add(this._filter);
    this._filter.onComplete = () => {
      this._eCompleteFilter()
    }

    // 足の置き方
    this._attentionFoot = new attentionFoot()
    this._attentionFoot.init();
    this.mainScene.add(this._attentionFoot);
    this._attentionFoot.onComplete = () => {
      this._eCompleteAttentionFoot()
    }


    // 裾の長さ
    this._attentionHem = new attentionHem()
    this._attentionHem.init();
    this.mainScene.add(this._attentionHem);
    this._attentionHem.onComplete = () => {
      this._eCompleteAttentionHem()
    }


    // 準備中
    this._ready = new ready()
    this._ready.init();
    this.mainScene.add(this._ready);
    this._ready.onComplete = () => {
      this._eCompleteReady()
    }

    // 作成中
    this._making = new making();
    this._making.init();
    this.mainScene.add(this._making);

    // ロゴ
    this._logo = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'logo.png')
    })
    this._logo.init();
    this.mainScene.add(this._logo);

    this.resize();

    this._intro.visible = false;
    this._attentionFoot.visible = false;
    this._attentionHem.visible = false;
    this._filter.visible = false;
    this._ready.visible = false;
    this._making.visible = false;


    glUpdate.add(() => {
      this.update();
    })

    glResize.add(() => {
      this.resize();
    });

  }

  setScanStatus(status){
    this._scanStatus = status
  }


  completeMesurement() {
    // 計測完了
    this._ready.startComplete()
  }


  completeData(opt) {


    // データ完成
    this.onCompleteMaking = opt.onComplete
    this._making.hide(opt)

  }

  selectedFilter() {
    // 指定完了
    this._filter.showNextBtn()
  }

  unSelectedFilter() {
    // 指定未完了
    this._filter.hideNextBtn()
  }



  _eCompleteIntro() {
    this.setScene(glType.SCENE.FILTER)
    this._filter.show()
    // 次は裾のアラート
    /*
    this.setScene(glType.SCENE.ATTENTIONHEM)
    this._attentionHem.show()
    */
  }


  _eCompleteFilter() {
    this.setScene(glType.SCENE.ATTENTIONHEM)
    this._attentionHem.show()
  }

  _eCompleteAttentionFoot(){
    // 次は計測中
    this.setScene(glType.SCENE.READY)
    this._ready.show()
  }

  _eCompleteAttentionHem(){
    this.setScene(glType.SCENE.ATTENTIONFOOT)
    this._attentionFoot.show()
  }

   _eRestartReady() {
    // 再計測
    this.setScene(glType.SCENE.READY)
    this._ready.restart()
  }


  _eCompleteReady() {

    // 次は作成中


    this.setScene(glType.SCENE.MAKING)
    this._making.onStartFinish = () => {
      this._eStartMakingFinish()
    }
    this._making.onComplete = () => {
      this._eCompleteMaking()
    }
    // すでに合成が完了していたらシーンを変更
    this._making.setScanStatus (this._scanStatus)
    this._making.show({
      delay:0,
      isPre:false
    });

  }

  _eStartMakingFinish() {

    // ロゴ消す
    this._logo.fadeOut({
      duration:1,
      delay:0,
      ease:Power1.easeInOut
    })

    // canvas透明にしておく
    this._bgAlpha = 0

  }


  _eCompleteMaking() {

    // canvas消す
    this.opt.el.style.display = 'none'
    this._renderOff()

    // たぶんこのへんで合図必要
    if(this.onCompleteMaking != null) {
      this.onCompleteMaking()
    }


    // this._logo.fadeOut({
    //   duration:0.5,
    //   delay:0,
    //   ease:Power1.easeOut
    //   // onComplete:() => {
    //   //   TweenMax.to(this.opt.el, 1, {
    //   //     y:-window.innerHeight * 1.25,
    //   //     ease:Power4.easeInOut,
    //   //     force3D:true,
    //   //     onComplete:() => {
    //   //       console.log('uuuuuuuuuuuu')
    //   //     }
    //   //   })
    //   // }
    // })

  }


  preRender (opt) {

    this.setScene(glType.SCENE.MAKING)
    this.mainScene.position.x = -9999;

    setTimeout(() => {
      if(opt.onComplete != null) {
        opt.onComplete();
        this.mainScene.position.x = 0;
        this._making.show({
          delay:0,
          isPre:true
        });
      }
    }, 1000 * 3);

  }



  setScene (scene) {
    this._intro.visible = false;
    this._filter.visible  = false;
    this._attentionFoot.visible = false;
    this._attentionHem.visible = false;
    this._ready.visible = false;
    this._making.visible = false;

    switch(scene) {

      case glType.SCENE.INTRO:
        this._bgColor = 0xe6eeed;
        this._intro.visible = true;
        break;
      case glType.SCENE.FILTER:
        this._bgColor = 0xe6eeed;
        this._filter.visible  = true;
        break;
      case glType.SCENE.ATTENTIONFOOT:
        this._bgColor = 0xe6eeed;
        this._attentionFoot.visible = true;
        break;
      case glType.SCENE.ATTENTIONHEM:
        this._bgColor = 0xe6eeed;
        this._attentionHem.visible = true;
        break;

      case glType.SCENE.READY:
        this._bgColor = glConf.KEY_COLOR;
        this._ready.visible = true;
        break;

      case glType.SCENE.MAKING:
        this._bgColor = glConf.KEY_COLOR;
        this._making.visible = true;
        break;

    }

    this._renderOff();
    glParam.isRender[scene] = true;

  }


  _renderOff() {

    glParam.isRender[glType.SCENE.INTRO] = false;
    glParam.isRender[glType.SCENE.READY] = false;
    glParam.isRender[glType.SCENE.MAKING] = false;
    glParam.isRender[glType.SCENE.ATTENTIONFOOT] = false;
    glParam.isRender[glType.SCENE.ATTENTIONHEM] = false;
    glParam.isRender[glType.SCENE.FILTER] = false;
  }



  update() {

    if(glParam.isRender[glType.SCENE.INTRO] || 
      glParam.isRender[glType.SCENE.FILTER] || 
      glParam.isRender[glType.SCENE.ATTENTIONFOOT] || 
      glParam.isRender[glType.SCENE.ATTENTIONHEM] || 
      glParam.isRender[glType.SCENE.READY] || 
      glParam.isRender[glType.SCENE.MAKING]) {
      this._render();
    }

    if(this._stats != null) {
      this._stats.update();
    }

  }


  _render() {

    if(glParam.isPause) {
      return
    }

    this.renderer.setClearColor(this._bgColor, this._bgAlpha);
    this.renderer.render(this.mainScene, this.camera);

    this._renderCnt++;

  }


  resize() {

    const w = window.innerWidth;
    const h = window.innerHeight;


    const margin = 25;
    this._logo.setSize(256 * 0.5, 256 * 0.5);
    this._logo.position.set(w * 0.5 - this._logo.width - margin, h * 0.5 - margin, 1.3);

    this.camera.aspect = w / h;
    this.camera.updateProjectionMatrix();
    this.camera.position.z = h / Math.tan(this.camera.fov * Math.PI / 360) / 2;

    this.renderer.setPixelRatio(window.devicePixelRatio || 1);
    this.renderer.setSize(w, h, true);


    this._render();



  }





}
