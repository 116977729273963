import { actions as appActions } from '../redux/modules/app'
import contents from './contents'
import shoesContents from './shoesContents'
import glTextures from './glTextures'
import glConf from './glConf'
import glType from './glType'
import glParam from './glParam'

interface PreloadImageObj {
  // イントロで使う画像
  introImages: Array<string>;
  // 3D生成中時のパーティクルで使う画像
  shoeImages: Array<string>;
}

class Webgl {

  store: any
  prevState: any
  currentState: any
  canvas: Object
  intro: any
  renderer: any
  camera: any
  shoes: any

  constructor() {

    this.store = undefined
    this.prevState = undefined
    this.currentState = undefined

    this.canvas = {}

    // イントロ〜計測完了
    this.intro = undefined
    this.renderer = undefined
    this.camera = undefined

    // 足カルテ
    this.shoes = undefined

    this.preloadImages = undefined

  }

  // ストアを登録して監視
  setRedux(store:any) {
    this.store = store
    // storeの変更を監視
    this.store.subscribe(this.handleStoreChange.bind(this))
  }

  // storeの変更を監視
  handleStoreChange() {
    // storeを更新
    this.prevState = this.prevState ? this.currentState : this.store.getState()
    this.currentState = this.store.getState()
    
    if ( this.intro ){
      this.intro.setScanStatus(this.currentState.app.scanStatus)
    }

    // エラーから再計測にきた場合
    if (this.prevState.app.networkError && !this.currentState.app.networkError && 
    (this.currentState.app.scene == 4 || this.currentState.app.scene == 3 ) ) {
      this.intro._eRestartReady()
      return
    }
  
    // エラーから再計測にきた場合
    if (this.prevState.recommend.filter != this.currentState.recommend.filter && this.currentState.app.scene ==  10 ) {
      const {gender, category, taste} = this.currentState.recommend.filter;

      if (gender.length == 0 || category.length == 0 || taste.length == 0 ) {
        this.intro.unSelectedFilter()
      } else {
        this.intro.selectedFilter()
      }
      return
    }

    // scanStatus: ‘received’, ‘scanned’, ‘3d_uploaded’, ‘processed’, ‘result_uploaded’
    if (!this.prevState.app.scanStatus && this.currentState.app.scanStatus && this.currentState.app.scanStatus != "received") {
      // 撮影完了
      this.completeMesurement()
    }
    // 合成完了
    if (this.prevState.app.scanStatus != "result_uploaded" && this.currentState.app.scanStatus == "result_uploaded") {
      // 足カルテを表示する
      this.store.dispatch(appActions.setScene(glType.SCENE.SHOES))
      // データ作成完了
      this.completeData()
    }
  }

  // 初期化
  initialize(opt) {

    this.canvas.animation = opt.el

    // テスト用
    // ===============================================================
    if(glConf.IS_PREVIEW) {
      document.onkeydown = (e) => {

        // Q
        if(e.keyCode == 81) {
          this.pause()
        }

        // W
        if(e.keyCode == 87) {
          this.resume()
        }

        // E
        if(e.keyCode == 69) {
          this.completeMesurement()
        }

        // T
        if(e.keyCode == 84) {
          console.log('AAAAAAAAAAA')
          this.store.dispatch(appActions.setScene(glType.SCENE.SHOES))
          this.completeData()
        }

        // S
        if(e.keyCode == 83) {

          if(this.shoes != null) {
            return
          }

          // DOMつくる
          const el = document.createElement('canvas')
          el.setAttribute('id', 'webgl-shoes')
          el.style.position = 'absolute';
          document.getElementById('app-root').appendChild(el)


          const test = document.createElement('div')
          test.style.position = 'absolute';
          test.style.width = '400px';
          test.style.height = '400px';
          test.style.backgroundColor = '#FF0000';
          document.getElementById('app-root').appendChild(test)
          test.addEventListener('click', () => {
            console.log('click')
          })

          this.showShoes({
            el:el,
            plyFileUrl:glConf.PATH_IMG + 'dummy/foot.pcd'
          }).then(() => {
            console.log('足カルテ表示始まった')
          })
        }

        // A
        if(e.keyCode == 65) {

          if(this.shoes == null) {
            return
          }

          this.hideShoes({}).then(() => {
            document.getElementById('app-root').removeChild(document.getElementById('webgl-shoes'))
          })
        }
      }
    }
    // ===============================================================


  }

  // 画像など必要なアセッツをプリロードする
  preload(preloadImageObj: PreloadImageObj): Promise<any> {
    return new Promise((resolve, reject) => {

      this.preloadImages = preloadImageObj

      // jpgになってたから仮でなおす
      this.preloadImages.introImages.forEach((v,i) => {
        this.preloadImages.introImages[i] = v.replace('jpg', 'png')
      })

      // 靴、空だったら一個いれておく
      if(this.preloadImages.shoeImages.length <= 0) {
        this.preloadImages.shoeImages.push(glConf.PATH_IMG + 'dummy/shoes_0.jpg')
      }

      let list = this.preloadImages.introImages.concat(this.preloadImages.shoeImages)
      list = list.concat([
        glConf.PATH_IMG + 'logo.png',
        glConf.PATH_IMG + 'intro_0.png',
        glConf.PATH_IMG + 'intro_1.png',
        glConf.PATH_IMG + 'ready_desc_foot_0.png',
        glConf.PATH_IMG + 'ready_desc_foot_1.png',
        glConf.PATH_IMG + 'ready_desc_text_0.png',
        glConf.PATH_IMG + 'ready_desc_text_1.png',
        glConf.PATH_IMG + 'ready_desc_guide_0.png',
        glConf.PATH_IMG + 'ready_desc_guide_1.png',
        glConf.PATH_IMG + 'ready_desc_guide_2.png',
        glConf.PATH_IMG + 'ready_desc_guide_3.png',
        glConf.PATH_IMG + 'ready_desc_guide_4.png',
        glConf.PATH_IMG + 'ready_desc_guide_5.png',
        glConf.PATH_IMG + 'ready_desc_guide_6.png',
        glConf.PATH_IMG + 'ready_ok.png',
        glConf.PATH_IMG + 'ready_radar.png'
      ])

      // 画像読み込み
      glTextures.load({
        list:list
      }).then(() => {
        this.setup().then(() => {
          resolve()
        })

      }).catch(() => {
        reject()
      })

    })
  }

  // アニメーションの停止
  pause() {
    glParam.isPause = true

    if(this.shoes != null) {
      this.shoes.useTrackball(false)
    }
  }

  // アニメーションの再生
  resume() {
    glParam.isPause = false

    if(this.shoes != null) {
      this.shoes.useTrackball(true)
    }
  }

  startAttentionHem() {
    this.store.dispatch(appActions.setScene(glType.SCENE.ATTENTIONHEM))
  }

  startAttentionFootPosition() {
    this.store.dispatch(appActions.setScene(glType.SCENE.ATTENTIONFOOT))
  }

  startMesurementReady() {
    this.store.dispatch(appActions.setScene(glType.SCENE.READY))
  }

  // 計測開始
  startMesurement() {
    //this.store.dispatch(appActions.setScene(2))
    this.store.dispatch(appActions.setScene(4))
  }

  // 計測完了
  completeMesurement() {
    this.intro.completeMesurement()
  }

  // データ作成完了
  completeData() {
    this.intro.completeData({
      onComplete: () => {
        // イントロアニメを消す
        this.store.dispatch(appActions.introAnimationed())
      }
    })
  }

  // 裾長さの説明
  startAttentionHemLength() {
    this.store.dispatch(appActions.setScene(2))
  }


  // パーティクルアニメーションちょっと流れた
  dispatchReadyMaking() {
    this.store.dispatch(appActions.generate3dDataOnReady())
  }


  // 足カルテの表示
  showShoes(opt: { el: HTMLElement, plyFileUrl: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.shoes != null) {
        return reject()
      }
    // this.intro.opt.el.style.display = 'none'
      this.shoes = new shoesContents({
        el:opt.el
      })

      let data;
      if(opt.plyFileUrl == undefined) {
        data = glConf.PATH_IMG + 'dummy/foot.pcd'
      } else {
        data = opt.plyFileUrl
      }
      this.shoes.show({
        data:data,
        onComplete:() => {
          resolve()
        }
      })
    })
  }

  // 足カルテ消す
  hideShoes(opt: any): Promise<any> {
    // アニメーション必要かもだけど一旦普通に消す
    return new Promise((resolve, reject) => {
      this.shoes.hide({
        onComplete:() => {
          this.shoes.dispose()
          this.shoes = null
          resolve()
        }
      })
    })
  }

  setup(): Promise<any> {
    return new Promise((resolve) => {
      // アニメーション用
      this.intro = new contents({
        el:this.canvas.animation,
        startCallback: () => {
          // スタートボタンのクリック
          //this.store.dispatch(appActions.setScene(glType.SCENE.ATTENTIONHEM))
          this.store.dispatch(appActions.setScene(glType.SCENE.FILTER))
        }
      })
      this.intro.preRender({
        onComplete:() => {
          resolve()
        }
      })
    })

  }

  // イントロから開始
  start() {
    this.intro.setScene(glType.SCENE.INTRO)
  }

}

export default new Webgl()
