// @flow
import React, { Component } from 'react'
import RefreshButton from './RefreshButton'

type Props = {
  appActions: Object,
  webglActions: Object,
  recommendActions: Object,
}

type State = {}

export default class Measurement extends Component<Props, State> {
  async componentDidMount() {
    const { appActions, webglActions, recommendActions } = this.props

    const { footUuid } = await appActions.getFootUuid(appActions.digitizerCode)

    appActions.scan(footUuid)
    const [measurement] = await Promise.all([
      appActions.watchMeasurement(footUuid),
    ])
    await appActions.setFiltering(footUuid)
    // 3DデータファイルをwebGL側に渡す
    webglActions.registerPlyFileUrl(measurement.file)


    const {
      left, right
    } = measurement
    let footLength = left.footMeasurement.footLength
    let footBoundingWidth = left.footMeasurement.footBoundingWidth

    if( left.footMeasurement.footLength < right.footMeasurement.footLength) {
      footLength = right.footMeasurement.footLength
      footBoundingWidth = right.footMeasurement.footBoundingWidth
    }
    // JISデータを取得
    //await appActions.getJisSize(footUuid)

    await recommendActions.registerRecommend({
      size: footLength,
      width: footBoundingWidth
    })
    await appActions.watchAnimationState()


    appActions.dataLayerPush({ pageID: 'measurement' })
  }

  render() {
    const { appActions } = this.props
    return <RefreshButton onClick={appActions.refresh} />
  }
}
