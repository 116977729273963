// @flow
import React from 'react'
import styled from 'styled-components'
import illustPng from './img/illust.png'
import n1 from './img/1.png'
import n2 from './img/2.png'
import n3 from './img/3.png'
import n4 from './img/4.png'
import n5 from './img/5.png'
import n6 from './img/6.png'
import n7 from './img/7.png'
import n8 from './img/8.png'
import n9 from './img/9.png'
import n10 from './img/10.png'
import { formatSize, formatAncleAngle } from '~/helpers/number'
import { FootMeasurement } from '../../types'

function DataList({
  right,
  left,
  karteDataLevel,
  heelWidthJa,
  instepHeightJa,
  ...rest
}: {
  right: FootMeasurement,
  left: FootMeasurement,
  karteDataLevel: string,
  heelWidthJa: Object,
  instepHeightJa: Object
}) {

  return (
    <Root {...rest}>
    {karteDataLevel == "1" &&  
      <Illust>
        <img src={illustPng} width={204} />
      </Illust>
    }
      <Table>
        <thead>
          <tr>
            <Th1 />
            <Th2>左足</Th2>
            <Th2>右足</Th2>
          </tr>
        </thead>
        <tbody>
          <tr>
            <Td1>
              {karteDataLevel == "1" && <Number src={n1} />}
              足長
            </Td1>
            <Td2>{`${formatSize(left.footMeasurement.footLength)}cm`}</Td2>
            <Td2>{`${formatSize(right.footMeasurement.footLength)}cm`}</Td2>
          </tr>
          <tr>
            <Td1>
              {karteDataLevel == "1" && <Number src={n2} />}
              足幅
            </Td1>
            <Td2>{left.footMeasurement.footWidth70Percent ? `${formatSize(left.footMeasurement.footBoundingWidth)}cm` : `${formatSize(left.footMeasurement.footWidth)}cm`}</Td2>
            <Td2>{right.footMeasurement.footWidth70Percent ? `${formatSize(right.footMeasurement.footBoundingWidth)}cm` : `${formatSize(right.footMeasurement.footWidth)}cm`}</Td2>
          </tr>
          {karteDataLevel == "1" &&  
          <tr>
            <Td1>
              <Number src={n3} />
              足囲
            </Td1>
            <Td2>{left.footMeasurement.footWidth70PercentCircumference ? `${formatSize(left.footMeasurement.footWidth70PercentCircumference)}cm` : `${formatSize(left.footMeasurement.footCircumference)}cm`}</Td2>
            <Td2>{right.footMeasurement.footWidth70PercentCircumference ? `${formatSize(right.footMeasurement.footWidth70PercentCircumference)}cm` : `${formatSize(right.footMeasurement.footCircumference)}cm`}</Td2>
          </tr>
          }
          {/*
          <tr>
            <Td1>
              <Number src={n4} />
              アーチの長さ
            </Td1>
            <Td2>{`${formatSize(left.footMeasurement.archLength)}cm`}</Td2>
            <Td2>{`${formatSize(right.footMeasurement.archLength)}cm`}</Td2>
          </tr>
          <tr>
            <Td1>
              <Number src={n5} />
              指の長さ
            </Td1>
            <Td2>{`${formatSize(left.footMeasurement.fingerLength)}cm`}</Td2>
            <Td2>{`${formatSize(right.footMeasurement.fingerLength)}cm`}</Td2>
          </tr>
          <tr>
            <Td1>
              <Number src={n6} />
              踵から親指の付け根
            </Td1>
            <Td2>{`${formatSize(left.footMeasurement.lengthHeelToThumbBigMound)}cm`}</Td2>
            <Td2>{`${formatSize(right.footMeasurementlengthHeelToThumbBigMound)}cm`}</Td2>
          </tr>
          <tr>
            <Td1>
              <Number src={n7} />
              踵から小指の付け根
            </Td1>
            <Td2>{`${formatSize(left.footMeasurement.lengthHeelToPinkySmallMound)}cm`}</Td2>
            <Td2>{`${formatSize(right.footMeasurement.lengthHeelToPinkySmallMound)}cm`}</Td2>
          </tr>
          */}
          <tr>
            <Td1>
              {karteDataLevel == "1" && <Number src={n8} />}
              踵幅
            </Td1>
            <Td2>{(karteDataLevel == "1")? `${formatSize(left.footMeasurement.ankleWidth)}cm`: heelWidthJa.left}</Td2>
            <Td2>{(karteDataLevel == "1")? `${formatSize(right.footMeasurement.ankleWidth)}cm`: heelWidthJa.right}</Td2>
          </tr>
          <tr>
            <Td1>
              {karteDataLevel == "1" && <Number src={n9} />}
              甲高
            </Td1>
            <Td2>{(karteDataLevel == "1")? `${formatSize(left.footMeasurement.instepHeight)}cm`: instepHeightJa.left }</Td2>
            <Td2>{(karteDataLevel == "1")? `${formatSize(right.footMeasurement.instepHeight)}cm`: instepHeightJa.right}</Td2>
          </tr>
        </tbody>
      </Table>
    </Root>
  )
}

export default DataList

const Root = styled.div``

const Illust = styled.div``

const Table = styled.table`
  width: 300px;
  position: relative;
  top: -20px;
  tr {
    border-bottom: 1px solid #cccccc;
  }
  td {
    font-size: 25px;
  }
`

const Th1 = styled.th`
  width: 125px;
`

const Th2 = styled.th`
  font-family: $({theme} => theme.font.bold);
  font-size: 20px;
  width: 45px;
  text-align: center;
  padding: 5px 0;
`

const Number = styled.img`
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 5px;
`

const Td1 = styled.td`
  padding: 5px;
`

const Td2 = styled.td`
  text-align: right;
  padding: 5px 0 5px 5px;
`
