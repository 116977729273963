// @flow
import axios from 'axios'
import { actions as appActions } from './app'
import { normalize, denormalize, schema } from 'normalizr'
import humps from 'humps'
import { cloudApi } from '~/api'
import createTagEntities from '~/helpers/createTagEntities'
import { Tags, SelectedTagItems, RecommendEntity } from '../../types'
import pictureUrl from '~/helpers/pictureUrl'

// $FlowFixMe
const { CLOUDV1 } = process.env.CONFIG

// Actions
const REGISTER_TAGS = 'recommend/REGISTER_TAGS'
const REGISTER_RECOMMEND_ENTITIES = 'recommend/REGISTER_RECOMMEND_ENTITIES'
const UPDATE_FAVORITES = 'recommend/UPDATE_FAVORITES'
const SELECT_TAG = 'recommend/SELECT_TAG'
const REMOVE_TAG = 'recoomend/REMOVE_TAG'
const PREFERENCE = 'recoomend/PREFERENCE'
const UPDATE_FILTER = 'recoomend/UPDATE_FILTER'

export const recommendItemsSchema = new schema.Entity(
  'recommendItems',
  {},
  { idAttribute: 'itemUuid' }
)

export const actions = {
  registerRecommend: ({ size, width }: { size: number, width: number }) => (
    dispatch: any,
    getState: any
  ): Promise<any> => {
    return new Promise(async resolve => {
      try {
        const {
          app: { clientCode, digitizerCode },
          recommend: { tags, filter}
        } = getState()
        // 開発時のみ固定値
        if (process.env.NODE_ENV === 'development') {
          size = 235.0
          width = 95.5
        }

        let filtering = {}
        if (filter.category.length > 0 ) {
          filtering["category"] = filter.category.join(",")
        }
    
        if( filter.gender.length > 0 ){
          filtering["target"] = filter.gender
        }
    
        if( filter.taste.length > 0 ){
          filtering["fit"] = filter.taste
        }

        const response = await axios({
          method: 'post',
          url: `${CLOUDV1}/storeapp/recommendation`,
          headers: {
            'X-CLIENT-CODE': clientCode,
            'X-DIGITIZER-CODE': digitizerCode
          },
          data: { 
            size, 
            width,
            ...filtering
          
          }
        })
        const camelizeResponse = humps.camelizeKeys(response)
        if (camelizeResponse.status !== 200) {
          dispatch(appActions.networkError('Recommend情報の取得に失敗しました。<br>再撮影してください。<br/><br/>RECOMMNEDAPIエラー'))
          throw new Error()
        }
        const { data } = camelizeResponse
        if (data.statusCode === 200) {
          // pictureUriのフォーマットをフラットな配列に直す
          const json = data.data.map(obj => {
            const tmp = { ...obj }
            const pictureUri = Object.keys(obj.pictureUri).map(key => {
              return pictureUrl(obj.pictureUri[key])
            })
            tmp.pictureUri = pictureUri
            return tmp
          })
          // 検索用のタグ生成
          const { tagItems, tagList } = createTagEntities(tags, json)
          dispatch({
            type: REGISTER_TAGS,
            payload: { tagItems, tagList }
          })
          // 正規化
          const normalized = normalize(json, [recommendItemsSchema])
          dispatch({
            type: REGISTER_RECOMMEND_ENTITIES,
            payload: { ...normalized }
          })
          resolve({ ok: true })
        } else {
          dispatch(appActions.networkError(`Recommend情報の取得に失敗しました。<br>再撮影してください。<br/><br/>${data.message}`)
          )
          throw new Error()
        }
      } catch (error) {
        console.error(error)
      }
    })
  },

  onSelectFilterCategory: (category:Array<string>) => (dispatch: any, getState: any) => {
    const {
      recommend: { filter }
    } = getState()
    let tmpFilter = {...filter}
    tmpFilter.category = [...category]
    dispatch({
      type: UPDATE_FILTER,
      payload: { filter: tmpFilter }
    })
  },
  onSelectFilterGender: (gender:string) => (dispatch: any, getState: any) => {
    const {
      recommend: { filter }
    } = getState()
    let tmpFilter = {...filter}
    tmpFilter.gender = gender
    dispatch({
      type: UPDATE_FILTER,
      payload: { filter : tmpFilter }
    })
  },
  onSelectFilterTaste: (taste: string) => (dispatch: any, getState: any) => {
    const {
      recommend: { filter }
    } = getState()
    let tmpFilter = {...filter}
    tmpFilter.taste = taste
    dispatch({
      type: UPDATE_FILTER,
      payload: { filter : tmpFilter }
    })
  },
  onSelect: (uuid: number) => (dispatch: any, getState: any) => {
    const {
      recommend: { favorites }
    } = getState()
    let tmpFavorites = [...favorites]
    if (favorites.includes(uuid)) {
      tmpFavorites = favorites.filter(fid => fid !== uuid)
    } else {
      tmpFavorites.push(uuid)
    }
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { favorites: tmpFavorites }
    })
  },
  onSelectPreference: ( preference : string) => (dispatch: any, getState: any) => {
    dispatch({
      type: PREFERENCE,
      payload: { preference: preference }
    })
  },
  onDeleteFavorite: (uuid: number) => (dispatch: any, getState: any) => {
    const {
      recommend: { favorites }
    } = getState()
    dispatch({
      type: UPDATE_FAVORITES,
      payload: { favorites: favorites.filter(fid => fid !== uuid) }
    })
  },
  onRegisterTryList: (favoriteItems: Array<RecommendEntity>) => (
    dispatch: any,
    getState: any
  ): Promise<any> => {
    const {
      app: { footUuid }
    } = getState()
    return new Promise(async (resolve, reject) => {
      const response = await cloudApi({
        method: 'post',
        url: `/storeapp/fititems/${footUuid}`,
        data: {
          items: favoriteItems.map(item => {
            return {
              itemUuid: item.itemUuid,
              fit: item.fit,
              type: 'fit'
            }
          })
        }
      })
      if (response.data.statusCode === 200) {
        return resolve({ ok: true })
      } else {
        dispatch(appActions.networkError('試着リストの登録に失敗しました'))
        return reject()
      }
    })
  },
  onSelectTag: (selectedTag: string, selectedLabel: string) => {
    return {
      type: SELECT_TAG,
      payload: { selectedTag, selectedLabel }
    }
  },
  onRemoveTag: (selectedTag: string) => {
    return {
      type: REMOVE_TAG,
      payload: { selectedTag }
    }
  }
}

// Reducer
export type State = {
  tags: Tags,
  availableTags: Tags,
  selectedTags: SelectedTagItems,
  nonSelectTags: SelectedTagItems,
  items: ?Array<number>,
  favorites: Array<number>,
  preference: ?string,
  filter: Object<any>
}

const initialState = {
  tags: [
    'brandName',
    'categoryName',
    'shoeTypeName',
    'targetName',
    'strap',
    'shoelace',
    'fit',
    'heelTypeName',
    'heelHeight',
    'ffColorId',
    'ffMaterial'
  ],
  filter: {
    gender: "",
    category: [],
    taste: ""
  },
  preference: "normal",
  availableTags: [],
  selectedTags: {
    brandName: null,
    categoryName: null,
    shoeTypeName: null,
    targetName: null,
    strap: null,
    shoelace: null,
    fit: null,
    heelTypeName: null,
    heelHeight: null,
    ffColorId: null,
    ffMaterial: null
  },
  nonSelectTags: {
    brandName: [],
    categoryName: [],
    shoeTypeName: [],
    targetName: [],
    strap: null,
    shoelace: null,
    fit: [],
    heelTypeName: [],
    heelHeight: [],
    ffColorId: [],
    ffMaterial: []
  },
  items: null,
  favorites: []
}

export default function reducer(state: State = initialState, action: any) {
  switch (action.type) {
    case REGISTER_RECOMMEND_ENTITIES: {
      const { result } = action.payload
      return { ...state, items: result }
    }
    case UPDATE_FAVORITES: {
      const { favorites } = action.payload
      return { ...state, favorites }
    }
    case REGISTER_TAGS: {
      const { tagItems, tagList } = action.payload
      return { ...state, nonSelectTags: tagItems, availableTags: tagList }
    }
    case PREFERENCE: {
      const { preference } = action.payload
      return { 
        ...state, 
        preference: preference
      }
    }
    case UPDATE_FILTER: {
      const { filter } = action.payload
      return { 
        ...state, 
        filter: filter
      }
    }
    case SELECT_TAG: {
      const { selectedTag, selectedLabel } = action.payload
      const tmpState = { ...state }
      state.selectedTags[selectedTag] = selectedLabel
      return tmpState
    }
    case REMOVE_TAG: {
      const { selectedTag } = action.payload
      const tmpState = { ...state }
      state.selectedTags[selectedTag] = null
      return tmpState
    }
  }
  return state
}

// misc
export const selectItems = ({
  recommend: { items },
  entities: { recommendItems }
}: Object) => {
  return denormalize(items, [recommendItemsSchema], { recommendItems })
}

export const selecteFavorites = ({
  recommend: { favorites },
  entities: { recommendItems }
}: Object) => {
  return denormalize(favorites, [recommendItemsSchema], { recommendItems })
}
