// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Filter from '~/components/Filter'
import { actions } from '~/redux/modules/app'
import { actions as recommendActions } from '~/redux/modules/recommend'


const mapStateToProps = state => ({
  filter: state.recommend.filter
})

const mapDispatchToProps = dispatch => ({
  refresh: () => dispatch(actions.refresh()),
  dataLayerPush: params => dispatch(actions.dataLayerPush(params)),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter)
