// @flow
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ThemeProvider } from 'styled-components'
import { actions as appActions } from './redux/modules/app'
import Loading from './components/Loading'
import ErrorDialog from './components/ErrorDialog'
import AutoRefresh from './components/AutoRefresh'
import IntroCanvas from './components/IntroCanvas'
import HomeContainer from './containers/HomeContainer'
import RecommendContainer from './containers/RecommendContainer'
import TryListContainer from './containers/TryListContainer'
import KarteContainer from './containers/KarteContainer'
import MeasurementContainer from './containers/MeasurementContainer'
import MeasurementReadyContainer from './containers/MeasurementReadyContainer'
import AttentionFootPositionContainer from './containers/AttentionFootPositionContainer'
import AttentionHemLengthContainer from './containers/AttentionHemLengthContainer'
import FilterContainer from './containers/FilterContainer'
import GlobalStyle from './components/GlobalStyle'
import theme from './theme'
import { actions as recommendActions } from './redux/modules/recommend'
import type { AppState } from './redux/modules/app'

type Props = {
  app: AppState,
  handleRefresh: Function,
  refresh: boolean,
  loggedIn: boolean,
  appActions: Object
}

// $FlowFixMe
const { refreshTimer } = process.env.CONFIG

class App extends Component<Props, {}> {
  canRefresh = () => {
    const {
      app: { scene, allowAutoRefresh }
    } = this.props
    // ホーム画面と計測中画面ではリロードさせない
    return scene !== 0 && scene !== 1 && scene !== 2 &&  scene !== 3 && allowAutoRefresh
  }

  handleRefresh = () => {
    this.props.appActions.refresh()
  }


  render() {
    const {
      app: { scene, introAnimationed, loading, networkError },
      appActions
    } = this.props

    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyle />
          <Fragment>
            {scene === 5 && <RecommendContainer />}
            {scene === 6 && <KarteContainer />}
            {scene === 7 && <TryListContainer />}
            {!introAnimationed && <IntroCanvas />}
            {scene === 0 && <HomeContainer />}
            {scene === 2 && <AttentionFootPositionContainer />}
            {scene === 1 && <AttentionHemLengthContainer />}
            {scene === 3 && <MeasurementReadyContainer />}
            {scene === 4 && <MeasurementContainer />}
            {scene === 10 && <FilterContainer />}
          </Fragment>
          <Loading loading={loading} />
          <ErrorDialog error={networkError} appActions={appActions} scene={scene} />
          <AutoRefresh
            refresh={this.canRefresh()}
            onRefresh={this.handleRefresh}
            refreshMs={refreshTimer}
          />
        </Fragment>
      </ThemeProvider>
    )
  }
}

const mapStateToProps = state => ({
  app: state.app
})

const mapDispatchToProps = dispatch => ({
  appActions: bindActionCreators({ ...appActions }, dispatch),
  recommendActions: bindActionCreators({ ...recommendActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
