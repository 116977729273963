import webgl from './index';
import glConf from './glConf';
import object3d from './object3d';
import image from './image';
import glTextures from './glTextures';
import glUtils from './glUtils';
import attentionHemNextBtn from './attentionHemNextBtn';

import readyDesc from './readyDesc'

export default class selectColorAndHeel extends object3d {


  constructor(opt) {

    super(opt);

    this._foot = undefined

    this._desc = undefined
    this._descCategory = undefined
    this._descTaste = undefined
    this._nextBtn = undefined
    this._btn = undefined

    this.onClick = undefined
    this.onComplete = undefined

  }


  init() {
    super.init();

    this._desc = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'filter_gender_desc_text.png')
    })
    this._desc.init();
    this._desc.setSize(190, 16);
    this._desc.alpha(0);
    this.add(this._desc)

    this._descCategory = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'filter_category_desc_text.png')
    })
    this._descCategory.init();
    this._descCategory.setSize(243, 16);
    this._descCategory.alpha(0);
    this.add(this._descCategory)

    this._descTaste = new image({
      tex:glTextures.get(glConf.PATH_IMG + 'filter_taste_desc_text.png')
    })
    this._descTaste.init();
    this._descTaste.setSize(278, 16);
    this._descTaste.alpha(0);
    this.add(this._descTaste)

    this._nextBtn = new attentionHemNextBtn();
    this._nextBtn.init();
    this.add(this._nextBtn);

    this._btn = document.createElement('div');
    this._btn.setAttribute('id', 'webgl-nextSelectHeelBtn');
    this._btn.style.position = 'absolute';
    //this._btn.style.backgroundColor = '#FF0000';
    this._btn.style.opacity = 0;
    this._btn.style.display = 'none'
    this._btn.addEventListener('click', () => {
      this.hide()
      webgl.startAttentionHem()
    })
    document.getElementById('app-root').appendChild(this._btn);

    this.resize();

  }

    show() {
      const duration = 1;
      const delay = 0.5;
      const ease = Power4.easeInOut;

      this._desc.fadeIn({
        duration:duration,
        delay:delay,
        ease:ease
      })

      this._descCategory.fadeIn({
        duration:duration,
        delay:delay,
        ease:ease
      })

      this._descTaste.fadeIn({
        duration:duration,
        delay:delay,
        ease:ease
      })

      TweenMax.to("#genderbox", duration, {
        opacity:1,
        delay:delay,
        ease:ease,
        onComplete:() => {
        }
      })

      TweenMax.to("#categorybox", duration, {
        opacity:1,
        delay:delay,
        ease:ease,
        onComplete:() => {
        }
      })

      TweenMax.to("#tastebox", duration, {
        opacity:1,
        delay:delay,
        ease:ease,
        onComplete:() => {
        }
      })
      // NEXTボタンは選択後に表示
      this._nextBtn.visible = false
  }

  // 消す
  hide() {

    document.getElementById('app-root').removeChild(this._btn)

    const duration = 1;
    const dx = -this.sw;
    const interval = 0.075;
    const ease = Power4.easeInOut;

    TweenMax.to(this._desc.position, duration, {
      x:this._desc.position.x + dx,
      delay:interval * 1,
      ease:ease
    });
    this._desc.fadeOut({
      duration:duration,
      delay:interval * 1,
      ease:ease
    });

    TweenMax.to(this._descCategory.position, duration, {
      x:this._descCategory.position.x + dx,
      delay:interval * 1,
      ease:ease
    });
    this._descCategory.fadeOut({
      duration:duration,
      delay:interval * 1,
      ease:ease
    })

    TweenMax.to(this._descTaste.position, duration, {
      x:this._descTaste.position.x + dx,
      delay:interval * 1,
      ease:ease
    });
    this._descTaste.fadeOut({
      duration:duration,
      delay:interval * 1,
      ease:ease
    })


    TweenMax.to("#genderbox", duration, {
      opacity:0,
      delay:interval * 1,
      ease:ease,
      onComplete:() => {
      }
    })

    TweenMax.to("#categorybox", duration, {
      opacity:0,
      delay:interval * 1,
      ease:ease,
      onComplete:() => {
      }
    })

    TweenMax.to("#tastebox", duration, {
      opacity:0,
      delay:interval * 1,
      ease:ease,
      onComplete:() => {
      }
    })

    this._nextBtn.hide({
      delay: interval * 2.5,
      onComplete: () => {
        this._eHided()
      }
    });

  }

  showNextBtn() {
    this._btn.style.display = 'block'
    this._nextBtn.visible = true
  }

  hideNextBtn() {
    this._nextBtn.visible = false
  }


  _eHided() {

    if(this.onComplete != undefined) {
      this.onComplete()
    }

  }



  update() {

    super.update();
  }


  resize() {

    super.resize();

    this._btn.style.width = '270px';
    this._btn.style.height = '270px';
    this._btn.style.bottom = '96px';
    this._btn.style.right = '40px';

    this._desc.position.set( ((this.sw * 0.5 ) - 50 ) * -1, ( this.sh * 0.5 ) - 120 , 0);
    this._descCategory.position.set( ((this.sw * 0.5 ) - 50 ) * -1, ( this.sh * 0.5 ) -290, 0);
    this._descTaste.position.set( ((this.sw * 0.5 ) - 50 ) * -1, ( this.sh * 0.5 ) -480, 0);
    this._nextBtn.position.set( 334, -154, 0);

  }
}