// @flow
import * as React from 'react'
import styled from 'styled-components'

type Props = {
  name: string,
  selected: boolean,
  onSelect: () => void
}
function SelectButton({ name, selected, onSelect, ...rest }: Props ) {
  console.log("selecteButton " , name, selected)
  return (
    <ButtonStyle onClick={onSelect}  selected={selected} {...rest}>{name}</ButtonStyle>
  )
}



export default SelectButton

const ButtonStyle = styled.button`
  font-size: 13px;
  font-family: ${({ theme }) => theme.font.bold};
  height: 50px;
  background: ${({ theme, selected }) => selected ? theme.color.yellow : "#fff" };
  padding: 0 27px;
  border-radius: 25px;
  color: #000;
  &:disabled {
    color: ${({ theme }) => theme.color.grey};
  }
`
