// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import ArrowButton from './iconButtons/Arrow'
import Button from './buttons/ButtonA'
import Label from './label/LabelA'
import AccountPr from './AccountPr'
import KarteTitle from './karte/Title'
import KarteButton from './karte/Button'
import KarteData from './karte/DataList'
import RecommendButton from './karte/RecommendButton'
import FootTypeModal from './FootTypeModal'
import StaffCallModal from './StaffCallModal'
import LogoPng from '~/assets/img/flicfit-logo.png'
import Modal from './Modal'
import KarteCanvas from './KarteCanvas'
import webgl from '~/webgl'
import { formatSize } from '~/helpers/number'
import { FootMeasurement } from '../types/'
import textPng from './karte/img/text.png'
import illustLeftPng from './karte/img/illustLeft.png'
import illustRightPng from './karte/img/illustRight.png'
import lineIconPng from './karte/img/ico_line.png'

type Props = {
  appActions: Object,
  plyFileUrl: string,
  choicesAndKarteQrCodeUrl: string,
  left: FootMeasurement,
  right: FootMeasurement,
  jis: {
    jisSize: string,
    jisWidth: string
  },
  heelWidthJa: Object, 
  instepHeightJa: Object,
  jisWidthDisplay: boolean,
  karteDataLevel: string,
  introAnimationed: boolean,
  footUuid: string,
  clientCode: string
}

type State = {
  modal: boolean,
  modalStaffCall: boolean,
  display: string,
  displayStaffCall: boolean

}

class Karte extends Component<Props, State> {
  state = {
    modal: false,
    modalStaffCall: false,
    display: 'none',
    displayStaffCall: false
  }

  handleLogout = () => {
    this.props.appActions.refresh()
  }

  handleToggleModal = (bool: boolean) => {
    this.setState({ modal: bool })
  }

  handleToggleModalStaffCall = (bool: boolean) => {
    console.log("handleToggleModalStaffCall")
    this.setState({ modalStaffCall: bool, displayStaffCall: true })
  }
  componentDidMount() {
    // webglの表示
    if (this.props.introAnimationed) {
      webgl.showShoes({
        el: document.getElementById('canvas-karte'),
        plyFileUrl: this.props.plyFileUrl
      })
      if ( !this.state.displayStaffCall ){
        setTimeout(() => this.handleToggleModalStaffCall(true), 10000);
      }
    }
    this.setState({ display: 'block' })
    
    this.props.appActions.dataLayerPush({ pageID: 'karte' })
  }

  componentWillUnmount() {
    // webglを消去
    webgl.hideShoes()
  }

  componentDidUpdate() {
    if (this.props.introAnimationed) {
      webgl.showShoes({
        el: document.getElementById('canvas-karte'),
        plyFileUrl: this.props.plyFileUrl
      })
      if ( !this.state.displayStaffCall ){
        setTimeout(() => this.handleToggleModalStaffCall(true), 10000);
      }
    }
  }

  render() {
    const {
      appActions,
      choicesAndKarteQrCodeUrl,
      left,
      right,
      jis,
      instepHeightJa,
      heelWidthJa,
      jisWidthDisplay,
      karteDataLevel,
      introAnimationed,
      footUuid,
      clientCode
    } = this.props

    const footId = footUuid.split("-");

    return (
      <Root>
        <KarteCanvas />
        <div style={{ position: 'relative' }}>
          <QRCode value={choicesAndKarteQrCodeUrl} clientCode={clientCode} />
          <Logo src={LogoPng} width={117} />
          <Title jis={jis} jisWidthDisplay={jisWidthDisplay} />
          <ModalButton type={0} onClick={() => this.handleToggleModal(true)} />
          <KarteDataBlock 
            left={left} 
            right={right} 
            karteDataLevel={karteDataLevel} 
            heelWidthJa={heelWidthJa}
            instepHeightJa={instepHeightJa}
            />
          <OsusumeButton
            dir="previous"
            onClick={() => {
              // Recommendに遷移
              appActions.setScene(5)
            }}
          />
          <FootUuid>{footUuid}</FootUuid>
          <TopButton onClick={this.handleLogout}>TOPに戻る</TopButton>
          {this.state.modal && (
            <Modal
              handleOutsideClick={() => this.handleToggleModal(false)}
              render={() => (
                <FootTypeModal
                  handleClose={() => this.handleToggleModal(false)}
                  type={right.footMeasurement.toeShapeType.replace('ToeShapeType.', '')}
                  dataLayerPush={appActions.dataLayerPush}
                />
              )}
            />
          )}

          {/* 定員呼び出しAlert */}
          {this.state.modalStaffCall && (
            <Modal
              handleOutsideClick={() => this.handleToggleModalStaffCall(false)}
              render={() => (
                <StaffCallModal
                handleClose={() => this.handleToggleModalStaffCall(false)}
              />
              )}
            />
          )}


        </div>
      </Root>
    )
  }
}

export default Karte

const Root = styled.div`
  background-color: #f3f3f3;
  height: 100vh;
`
const Blur = styled.div`
  display: ${({ display }) => display};
  -ms-filter: blur(6px);
  filter: blur(6px);
  height: 100vh;
`
const LineText = styled.h3`
  position: fixed;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  span {
    color: #00c300;
  }
`
const QRText = styled.img`
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
`
const LineIcon = styled.img`
  position: fixed;
  top: 60px;
  left: 120px;
  pointer-events: none;
`
const QRIllustLeft= styled.img`
  position: fixed;
  top: 40px;
  left: 70px;
  pointer-events: none;
`
const QRIllustRight= styled.img`
  position: fixed;
  top: 30px;
  right: 5px;
  pointer-events: none;
`

const QRCode = styled(AccountPr)`
  position: fixed;
  top: 100px;
  right: 0;
  pointer-events: none;
`

const BackButton = styled(ArrowButton)`
  position: fixed;
  top: 15px;
  left: 15px;
`

const TopButton = styled(Button)`
  position: fixed;
  bottom: 37px;
  left: 15px;
`

const Logo = styled.img`
  position: fixed;
  top: 24px;
  right: 24px;
  pointer-events: none;
`
const FootId = styled(Label)`
  position: fixed;
  bottom: 37px;
  right: 15px;
  pointer-events: none;
`

const Title = styled(KarteTitle)`
  position: fixed;
  top: 78px;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
`

const ModalButton = styled(KarteButton)`
  position: fixed;
  top: 205px;
  left: 116px;
`

const KarteDataBlock = styled(KarteData)`
  position: fixed;
  bottom: 30px;
  right: 30px;
`

const FootUuid = styled.div`
  position: fixed;
  bottom: 0;
  right: 10px;
  color: #999;
`
const OsusumeButton = styled(RecommendButton)`
  position: fixed;
  bottom: 37px;
  left: 378px;
`