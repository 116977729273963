// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import IconClose from '~/components/iconButtons/Close'
import typeJson from '~/assets/json/foottype.json'
import webgl from '~/webgl'

type Props = {
  handleClose: () => void,
}

class StaffCallModal extends Component<Props> {
  componentDidMount() {
    webgl.pause()
  }

  componentWillUnmount() {
    webgl.resume()
  }

  render() {
    const { handleClose, type } = this.props

    return (
      <Root>
        <CloseButton onClick={handleClose} />
        <Contents>
        <Text>あなたの足に合ったブランドの傾向を知りたい方は、</Text>
        <Text>お近くのスタッフをお呼びください</Text>
        </Contents>
      </Root>
    )
  }
}

export default StaffCallModal

const Root = styled.div`
  width: 750px;
  height: 550px;
  position: relative;
  background-color: #fff;
  border-radius: 15px;
  padding: 25px;
`

const CloseButton = styled(IconClose)`
  position: absolute;
  top: 12px;
  right: 12px;
`
const Contents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80%;
  text-align: center;

`
const Text = styled.p`
  font-size: 20px;
  font-weight: bold;
`