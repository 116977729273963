// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import RefreshButton from './RefreshButton'
import Thumbnail from './thumbnail/SelectThumbnail'
import Button from './buttons/SelectButton'

const { filterGender, filterCategory, filterTaste } = process.env.CONFIG

type Props = {
  refresh: () => void,
  dataLayerPush: (params: { pageID: string }) => void,
  recommendActions: Object,
  filter: Object
}

export default class Filter extends Component<Props> {
  componentDidMount() {
    this.props.dataLayerPush({ pageID: 'Filter' })
  }

  isSelected = (gender: string) => {
    return this.props.filter.gender == gender
  }

  handleSelect = (value: string) => () => {
    this.props.recommendActions.onSelectFilterGender(value)
  }

  isCategorySelected = (value: string) => {
    return this.props.filter.category.includes(value)
  }

  handleCategorySelect = (value: string) => () => {
    const  { category } = this.props.filter
    let tmpCategory = [...category]
    if (category.includes(value)) {
      tmpCategory = category.filter(fid => fid !== value)
    } else {
      tmpCategory.push(value)
    }
    console.log(tmpCategory)
    this.props.recommendActions.onSelectFilterCategory(tmpCategory)
  }

  isTasteSelected = (value: string) => {
    return this.props.filter.taste == value
  }

  handleTasteSelect = (value: string) => () => {
    this.props.recommendActions.onSelectFilterTaste(value)
  }


  render() {
    const { refresh } = this.props
    console.log("props ", this.props)

    let count = 0
    console.log("FLITER")

    return  ( 
      <Root>
      <RefreshButton onClick={refresh} />

      <Gender id="genderbox">
        {filterGender.map(item => {
          count++
          let classNameStr = ''
          //classNameStr += count % 3 === 2 ? 'center' : ''
          classNameStr += 'center'
          return (
            <li key={item.value} className={classNameStr}>
            { item.value && 
              <Button
                name={item.label}
                selected={this.isSelected(item.value)}
                onSelect={this.handleSelect(item.value)}
              />
            }
            </li>
          )
        })}
      </Gender>

      <Category id="categorybox">
        {filterCategory.map(item => {
          count++
          let classNameStr = ''
          //classNameStr += count % 3 === 2 ? 'center' : ''
          classNameStr += 'center'
          return (
            <li key={item.value} className={classNameStr}>
            { item.value && 
              <Button
                name={item.label}
                selected={this.isCategorySelected(item.value)}
                onSelect={this.handleCategorySelect(item.value)}
              />
            }
            </li>
          )
        })}
      </Category>

      <Taste id="tastebox">
        {filterTaste.map(item => {
          count++
          let classNameStr = ''
          //classNameStr += count % 3 === 2 ? 'center' : ''
          classNameStr += 'center'
          return (
            <li key={item.value} className={classNameStr}>
            { item.value && 
              <Button
                name={item.label}
                selected={this.isTasteSelected(item.value)}
                onSelect={this.handleTasteSelect(item.value)}
              />
            }
            </li>
          )
        })}
      </Taste>


      </Root>
    )
  }
}



const Root = styled.div`
`
const Gender = styled.ul`
  position: absolute;
  top: 160px;
  margin-left: 50px;
  opacity: 0;
  display: flex;
  > li {
    margin-left: 30px;
  }
`

const Category = styled.ul`
  position: absolute;
  top: 340px;
  margin-left: 50px;
  opacity: 0;
  display: flex;
  > li {
    margin-left: 30px;
  }
`

const Taste = styled.ul`
  position: absolute;
  top: 520px;
  margin-left: 50px;
  opacity: 0;
  display: flex;
  > li {
    margin-left: 30px;
  }
`
